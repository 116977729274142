import type { CurrencyDisplay } from '~/types/currencyDisplay'

/*
 * Return results like "20,00 € par jour" or "€ par jour" if unitOnly is true
 */
export function useFormatCurrencyPerDay() {
  const { $i18n: { t, n } } = useNuxtApp()
  const formatCurrency = useFormatCurrency()

  return ({
    count = 0,
    currency,
    unitDisplay = 'long',
    unitOnly = false,
    maxDigit = 2,
  }: {
    count?: number
    currency: CurrencyDisplay
    unitDisplay?: string
    unitOnly?: boolean
    maxDigit?: number
  }): string => {
    const params = {
      currency_string: unitOnly
        ? formatCurrency({ count, currency: { ...currency, minimumFractionDigit: 0, maximumFractionDigit: maxDigit } })
        : n(count, { ...currency, minimumFractionDigits: 0, maximumFractionDigits: maxDigit }),
    }

    if (unitDisplay === 'narrow') {
      return t('commons.unit.currency_per_day.narrow_dynamic', params)
    }
    else if (unitDisplay === 'short') {
      return t('commons.unit.currency_per_day.short_dynamic', params)
    }

    return t('commons.unit.currency_per_day.long_dynamic', params)
  }
}
